@font-face {
	font-family: "Anka";
	src: local("GoldmanBold"),
		url("../fonts/anka/ankaclm-bold-webfont.ttf") format("truetype");
	font-weight: bold;
}

@font-face {
	font-family: "Comix";
	src: local("GoldmanBold"),
		url("../fonts/comix/comixno2clm_medium-webfont.ttf") format("truetype");
	font-weight: bold;
}

.yoga-page {
	margin-block-start: 2rem;
	line-height: 1.5;
	font-size: 1.2rem;
	font-weight: 400;
	max-width: 50rem;
	margin-inline: auto;
}

.yoga-page header {
	margin-block-end: 2rem;
}

.yoga-page section {
	margin-block: 2rem;
}

#yoga-subheader {
	color: navy;
	margin-block: 1rem;
}

.yoga-down-desc {
	margin-block-start: 2rem;
	margin-block-end: 8rem;
	font-family: 'Comix';
	color: brown;
	font-size: 1.3em;
}

.yoga-end-p {
	margin-block-start: 2rem;
	font-size: 1.1em;
	font-weight: bold;
	font-family: 'Anka';
	text-shadow: 0 0 10px yellow;
	color: rgb(225, 124, 0);
	font-family: 'Anka';
	letter-spacing: 5px;
}