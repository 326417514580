.container {
  justify-content: space-between;
  width: 80%;
  margin-left: auto;
  text-align: center;
}

@media (max-width: 675px){
  .container {
    flex-direction: column;
    height: 100vh;
    width: 100%;
  }
}
