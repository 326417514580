/* SPINNER STYLING */
.spinner {
    display: block;
    margin: 10rem auto;
    width: 100px;
    height: 100px;
    border: 10px solid var(--light-primary);
    border-top: 10px solid var(--dark-primary);
    border-radius: 50%;
    opacity: .5;
    animation: spin 2s linear infinite;
}

/* ANIMATION TO MAKE THE SPINNER SPIN INFINITLY */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}