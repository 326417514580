.gallery-title {
    font-size: 1.5rem;
}

.gallery-container {
    /* border: 1px solid black; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4em 1em;
}

.gallery-item {
    border-radius: 6px;
    cursor: pointer;
    transition: transform .3s ease,
        box-shadow .3s ease;
}

.gallery-item:hover,
.gallery-item:focus {
    box-shadow: 1px 1px 15px;
    transform: scale(1.02);
}

.gallery-container img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

@media (max-width: 600px) {
    .gallery-container {
        grid-template-columns: 1fr;
        grid-gap: 1em;
    }

    .gallery-title {
        font-size: 1.3rem;
    }
}

@media (min-width: 800px) {
    .gallery-container {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 1em;
    }

    .gallery-title {
        font-size: 2rem;
    }
}