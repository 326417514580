.contact {
	position: fixed;
	display: grid;
	place-items: center;
	width: 50px;
	height: 50px;
	aspect-ratio: 1;
	top: 1rem;
	right: 5rem;
	color: #FFFF;
	background-color: var(--dark-primary);
	filter: blur(0);
	padding: 1rem;
	border-radius: 50%;
	transform: scale(1);
	animation: float 4s infinite;
	z-index: 10000000;
	cursor: pointer;
	transition: box-shadow .2s ease,
		background-color .3s ease,
}

.contact:hover,
.contact:focus,
.contact-icon:hover,
.contact-icon:focus {
	animation-play-state: paused;
}

.contact-icon:hover,
.contact-icon:focus {
	filter: blur(1px);
}

.contact-icon {
	font-size: .8em;
	transition: filter .2s ease;
	display: block;
	text-align: center;
	font-weight: bold;
}

@media (min-width: 675px) {
	.contact {
		position: fixed;
		width: 80px;
		height: 80px;
		right: 1rem;
	}

	.contact-icon {
		display: block;
		font-size: 1em;
	}
}