.modal-title {
    color: var(--light-secondary);
    font-size: 2rem;
    margin-bottom: 1rem;
}

.modal-summary {
    font-size: 1rem;
}

.modal-container {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 100000;
    /* HEIGHEST Z-INDEX */
    color: #FFFF;
    backdrop-filter: blur(5px);
    animation: reveal .3s ease;
}

.modal-cover-image {
    max-width: 70%;
    margin-bottom: 2rem;
    border-radius: 6px;
}

@media (min-width: 675px) {
    .modal-cover-image {
        max-width: 400px;
        font-size: 1.2em;
        object-fit: cover;
    }

    .modal-full-screen {
        max-width: 80%;
    }
}